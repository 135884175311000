'use client';
import {domAnimation, LazyMotion, motion} from 'framer-motion';
import {type CSSProperties, type PropsWithChildren} from 'react';

const containerStyles: CSSProperties = {
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
};

export default function Template({children}: PropsWithChildren): React.JSX.Element {
    return (
        <LazyMotion features={domAnimation}>
            <div style={{overflowX: 'hidden'}}>
                <motion.div
                    animate={{x: 0, opacity: 1}}
                    initial={{x: 20, opacity: 0}}
                    style={containerStyles}
                    transition={{ease: 'easeInOut', duration: 0.75}}
                >
                    {children}
                </motion.div>
            </div>
        </LazyMotion>
    );
}
